<template>
  <div class="tinymce-editor">
    <editor v-model="myValue"
            :init="init"
            :disabled="!edit_bar_show"
    >
      <!--      @onClick="onClick"-->
    </editor>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme.min'
import 'tinymce/skins/ui/oxide/skin.min.css'
import Editor from '@tinymce/tinymce-vue'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/icons/default'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/save'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/autoresize'

import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/contextmenu'

// import axios from "../../http/axios";
export default {
  name: 'TinyMCE',
  components: {
    Editor
  },
  props: {
    edit_bar_show: {
      type: Boolean,
      default: false
    },
    doc_id: {
      type: Number,
      default: -1
    },
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: ''
    },
    plugins: {
      type: [String, Array],
      default: 'link lists image code colorpicker media table textcolor wordcount contextmenu ' +
          'save autosize paste preview imagetools '
    },
    // toolbar: {
    //   type: [String, Array],
    //   default: ['undo redo  |  lineheight|formatselect | fontselect | fontsizeselect| bold italic underline strikethrough  ' ,
    //     'cut copy paste| alignleft aligncenter alignright alignjustify| bullist numlist | outdent indent blockquote| lists table link code | removeformat|preview ']
    // },
  },
  data() {
    return {
      //初始化配置
      init: {
        statusbar: this.edit_bar_show, // 显示字数
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        height: 700,
        letterspacing_formats: '0 0.5pt 1pt 1.5pt 2pt 3pt',
        lineheight_formats :'1 1.1 1.2 1.3 1.4 1.5 2 2.5 3',
        /**
         * 为了移除边框，原来是'/tinymce/skins/ui/oxide'，
         * 修改方法在https://github.com/tinymce/tinymce/issues/6032
         * 自定义皮肤在http://skin.tiny.cloud/t5/
         */
        skin_url: '/tinymce/skins/ui/CUSTOM',
        plugins: 'link lists image code colorpicker media table textcolor wordcount contextmenu ' +
            'save autosize paste preview imagetools ' + (this.edit_bar_show?'':'autoresize'),
        toolbar:['undo redo  |  lineheight|formatselect | fontselect | fontsizeselect| bold italic underline strikethrough  ' ,
          'cut copy paste| alignleft aligncenter alignright alignjustify| bullist numlist | outdent indent blockquote| lists table link code | removeformat|preview '],
        branding: false,
        menubar: true,
        paste_word_valid_elements: '*[*]',        // word需要它
        paste_data_images: true,                  // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false,     // 插入word文档需要该属性
        font_formats:"微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;仿宋=FangSong;楷体=KaiTi;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats"
        // 配置图片上传的功能
        // images_upload_handler:(blobInfo, success, failure) => {
        //   this.handleImgUpload(blobInfo, success, failure)
        // },
        // //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        // images_upload_handler: (blobInfo, success, failure) => {
        //   const img = 'data:image/jpeg;base64,' + blobInfo.base64()
        //   success(img)
        // },
      },
      myValue: this.value
    }
  },
  created() {
    if (this.edit_bar_show === true) {
      // this.init.toolbar = this.toolbar
      this.init.menubar = true
      this.init.statusbar = true
      this.init.skin_url = '/tinymce/skins/ui/oxide'
    } else {
      this.init.toolbar = ''
      this.init.menubar = ''
      this.init.statusbar = false
      this.init.skin_url = '/tinymce/skins/ui/CUSTOM'
    }
    console.log('bar值')
    console.log(this.edit_bar_show)
    tinymce.init({})
  },
  methods: {

    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },
    //可以添加一些自己的自定义事件
    handleSubmit() {
      var _this = this
      console.log('提交内容')
      console.log(this.myValue)
      console.log('提交内容-完毕')
      if (this.myValue === '') {
        this.myValue = ' '
      }
      this.$api.document.end({
        doc_id: _this.doc_id
      }, _this.myValue).then(res => {
        if (res.code === 200 ){
          _this.$message({
            message: '文章上传成功',
            category: 'success'
          })
          _this.$emit('submitSuccess')
        } else {
          _this.$message({
            message: res.msg,
            category: 'error'
          })
        }
      })
    },
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style scoped>
</style>
