import { render, staticRenderFns } from "./TinyMCE.vue?vue&type=template&id=484b9e9b&scoped=true&"
import script from "./TinyMCE.vue?vue&type=script&lang=js&"
export * from "./TinyMCE.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484b9e9b",
  null
  
)

export default component.exports